import "core-js/modules/es.array.push.js";
import List from '@/components/List';
import { ElTag, ElTooltip } from 'element-plus';
import { computed, defineComponent, inject, toRefs } from 'vue';
import { isNullOrUnDef } from '@/utils/is';
import { handleISExpired } from '@/utils';
import sleep from '../../utils/sleep';
import { useRouter } from "vue-router";
export default defineComponent({
  name: 'StudentList',
  components: {
    ElTag,
    List,
    ElTooltip
  },
  setup(props, {
    emit
  }) {
    const router = useRouter();
    const refRoot = inject('rootProvide');
    // 从未上线的Ids
    const neverOnlineIds = computed(() => refRoot.studentList.filter(student => [3, 4].includes(student.onlineStatus)).map(student => student.objectid));
    function isOnline(onlineStatus) {
      return onlineStatus === 1;
    }
    function onSettings(student) {
      refRoot.cmdProps.objectid = student.objectid;
      refRoot.cmdProps.visible = true;
    }
    async function onRowClick(item, index) {
      // 从未上线
      if (![1, 2, 4].includes(item.onlineStatus)) return;
      //判断当前员工证是否过期
      if (item.onlineStatus === 4) return handleISExpired();
      // 点击当前员工,默认勾选
      if (!refRoot.studentCheckedIds.includes(item.objectid)) {
        refRoot.studentCheckedIds.push(item.objectid);
      }
      //当值选中一位员工时，员工点不在地图可视区域内，设置跳转到可视区域
      if (refRoot.currentCardId === item.objectid) {
        refRoot.currentCardId = null;
        await sleep(200);
      }
      refRoot.currentCardId = item.objectid;
    }
    function onCheckedChange(checked, checkeds, item) {
      // 点击勾选时,默认选中当前员工
      if (checked && !isNullOrUnDef(item)) {
        onRowClick(item);
      }
    }
    function onCheckedAll(checked) {
      if (checked && refRoot.studentCheckedIds.length > 0) {
        // 勾选所有时, 默认选中第一个员工
        const objectid = refRoot.studentCheckedIds[0];
        refRoot.currentCardId = objectid;
      }
    }
    return {
      ...toRefs(refRoot),
      neverOnlineIds,
      isOnline,
      onSettings,
      onRowClick,
      onCheckedChange,
      onCheckedAll
    };
  }
});