import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5a57fb06"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "liveSideBottom"
};
const _hoisted_2 = {
  class: "list_title"
};
const _hoisted_3 = {
  class: "list_filter"
};
const _hoisted_4 = {
  class: "list_content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_SearchStudent = _resolveComponent("SearchStudent");
  const _component_OnlineRadio = _resolveComponent("OnlineRadio");
  const _component_StudentList = _resolveComponent("StudentList");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Icon, {
    style: {
      "margin-right": "3px"
    },
    name: "Operation"
  }), _createTextVNode("设备列表 ")]), _createVNode(_component_SearchStudent, {
    onSelect: _ctx.onSelectStudent
  }, null, 8, ["onSelect"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_OnlineRadio, {
    modelValue: _ctx.filterType,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filterType = $event),
    total: _ctx.studentTotal,
    online: _ctx.studentOnline,
    offline: _ctx.studentOffline
  }, null, 8, ["modelValue", "total", "online", "offline"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_StudentList)])]);
}