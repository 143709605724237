export default [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '在线',
  },
  {
    value: 2,
    label: '离线',
  },
];
