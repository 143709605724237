import "core-js/modules/es.array.push.js";
import { ElScrollbar, ElTree } from 'element-plus';
import { defineComponent, defineAsyncComponent, reactive, toRefs, inject } from 'vue';
import StudentList from './StudentList.vue';
import OnlineRadio from '@/components/OnlineRadio';
import { isNullOrUnDef } from '@/utils/is';
export default defineComponent({
  name: 'LiveStuentList',
  components: {
    OnlineRadio,
    ElTree,
    ElScrollbar,
    StudentList,
    SearchStudent: defineAsyncComponent(() => import('@/components/SearchStudent'))
  },
  setup() {
    const refRoot = inject('rootProvide');
    const refData = reactive({
      onlineState: 0,
      checkedIds: []
    });
    // 搜索框,选择员工
    async function onSelectStudent(item) {
      // 通知加载员工列表
      if (item == null) {
        refRoot.filterImei = "";
        return;
      }
      if (!refRoot.filterClassIds.includes(item.classId)) {
        refRoot.filterClassIds.unshift(item.classId);
      }
      refRoot.filterImei = item.mdtid;
      // 搜索 岗位类型Id
      refRoot.currentClassId = item.typeId;
      // 等待员工列表加载完成
      const current = refRoot.studentList.find(student => student.objectid === item.objectid);
      // 从未上线
      if (isNullOrUnDef(current) || ![1, 2].includes(current.onlineStatus)) {
        return;
      }
      if (!refRoot.studentCheckedIds.includes(current.objectid)) {
        refRoot.studentCheckedIds.push(current.objectid);
      }
      refRoot.currentCardId = current.objectid;
    }
    return {
      ...toRefs(refData),
      ...toRefs(refRoot),
      onSelectStudent
    };
  }
});