import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67a5662e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "studentList"
};
const _hoisted_2 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_List = _resolveComponent("List");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_List, {
    ref: "listRef",
    modelValue: _ctx.studentCheckedIds,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.studentCheckedIds = $event),
    list: _ctx.studentList,
    checkDisabled: _ctx.neverOnlineIds,
    isAlways: false,
    idKey: "objectid",
    checkbox: "",
    pagination: "",
    onRowClick: _ctx.onRowClick,
    onCheckedChange: _ctx.onCheckedChange,
    onCheckedAll: _ctx.onCheckedAll
  }, {
    default: _withCtx(({
      item
    }) => [_createElementVNode("div", {
      class: "itemCls cursor-pointer",
      title: `${item.objectname}, ${item.schoolName}, ${item.gradeName}${item.className}`
    }, [_createVNode(_component_Icon, {
      class: _normalizeClass(["avatar_icon", ['active2', 'active1', 'active2', 'active2'][item.onlineStatus]]),
      name: "UserFilled"
    }, null, 8, ["class"]), _createElementVNode("div", {
      class: _normalizeClass(["nameCls text-ellipsis", ['active2', 'active1', 'active2', 'active2'][item.onlineStatus]])
    }, _toDisplayString(item.objectname), 3), _createVNode(_component_el_tag, {
      class: "online_tag",
      type: ['danger', 'success', 'danger', 'info', 'info'][item.onlineStatus]
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(["离线", "在线", "离线", "从未上线", "过期"][item.onlineStatus]), 1)]),
      _: 2
    }, 1032, ["type"]), _createVNode(_component_Icon, {
      class: "settings_icon",
      name: "icon-shezhi",
      onClickPassive: _withModifiers($event => _ctx.onSettings(item), ["stop"])
    }, null, 8, ["onClickPassive"])], 8, _hoisted_2)]),
    _: 1
  }, 8, ["modelValue", "list", "checkDisabled", "onRowClick", "onCheckedChange", "onCheckedAll"])), [[_directive_loading, _ctx.studentLoading]])]);
}