import { computed, toRefs, defineComponent, reactive } from 'vue';
import { ElRadioGroup, ElRadioButton } from 'element-plus';
import options from './options';
const __default__ = defineComponent({
  name: 'OnlineRadio',
  components: {
    ElRadioGroup,
    ElRadioButton
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: 0
    },
    isCount: {
      type: Boolean,
      default: true
    },
    total: {
      type: Number,
      default: 0
    },
    online: {
      type: Number,
      default: 0
    },
    offline: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const refData = reactive({
      count: computed(() => options.length)
    });
    function getCount(online) {
      if (online === 0) return props.total;
      if (online === 1) return props.online;
      if (online === 2) return props.offline;
    }
    return {
      ...toRefs(refData),
      options,
      getCount
    };
  }
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "f88ca872": _ctx.count
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;